import { Basket as BasketTypes } from '~/models/basket.d';
import { StockStatus } from '~/shared/stock-status/stock-status.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';

export type ProductItemIDProps = {
    stockStatus: BasketTypes.IStockStatusDto | undefined;
    caption: string;
    captionClassName?: string;
};

export function ProductItemStockStatus({ stockStatus, caption, captionClassName }: ProductItemIDProps) {
    return (
        <ValueWithCaption caption={caption} captionClassName={captionClassName}>
            <StockStatus state="ready" stock={stockStatus} />
        </ValueWithCaption>
    );
}
